import './Capabilities.scss';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Tab from '@components/Tab';
import SectionIcon from '../components/SectionIcon';
import Cube from './assets/multicube.inline.svg';
import carretLeft from './assets/carret-left.svg';
import carretRight from './assets/carret-right.svg';

const texts: any = {
  universal: (
    <>
      Dense and sparse multi-dimensional arrays are able to efficiently capture all types of data, for any current and future data workload.
      <br />
      <br />
      With the ability to model data using various dimensions, configurable tiling and a variety of layouts on the storage medium, TileDB
      Embedded is a versatile, yet super performant, storage engine, ideal for any application. TileDB Embedded is currently used in a broad
      spectrum of industries, including healthcare, telecommunications, defense, finance, earth observation and many more.
    </>
  ),
  performant: (
    <>
      TileDB Embedded is an optimized C++ library, implemented with multi-threading and vectorization, supporting advanced indexing and
      useful filters for compression, checksums and encryption.
      <br />
      <br />
      Dense and sparse multi-dimensional arrays are first-class citizens in the storage engine, which can be efficiently tailored to provide
      blazing performance for any data application. TileDB Embedded implements multi-dimensional indexing with lightweight metadata (for
      dense arrays) and efficient R-trees (for sparse arrays), resulting in rapid slicing.
    </>
  ),
  columnar: (
    <>
      TileDB Embedded supports arrays with multiple attributes of different types for storing multiple values in its cells, similar to
      traditional dataframes.
      <br />
      <br />
      It employs a columnar data format where values from the same attribute are stored adjacently on the storage medium. This approach
      maximizes the compressibility of the attribute values and enables fast subsetting of attributes by retrieving only the useful
      attribute values for each query and pruning undesired columns.
    </>
  ),
  'cloud-native': (
    <>
      The TileDB Embedded storage format is particularly optimized for storing and retrieving data on cloud object stores such as AWS S3,
      Azure Blob Storage and Google Cloud Storage, as well as on any other object store, such as MinIO.
      <br />
      <br />
      TileDB Embedded accounts for the unique characteristics of each service. Object immutability, minimization of REST requests, object
      consolidation, efficient metadata handling and indexing, are all handled gracefully by the TileDB Embedded library resulting in superb
      IO performance.
    </>
  ),
  versioned: (
    <>
      All data writes handled by TileDB Embedded are immutable and timestamped.
      <br />
      <br />
      This design powers several features, providing the ability to time travel and read an array considering a subset of write operations,
      undo a write operation, and in general have absolute control over writes and reads on the array for maximum reproducibility. The
      TileDB Embedded cloud-native data format defines immutable timestamped data folders, and its engine implements efficient slicing over
      the timestamps for time traveling.
    </>
  ),
  interoperable: (
    <>
      TileDB Embedded is built in C++ but it exposes a large variety of APIs, including C, C++, C#, Python, R, Java and Go.
      <br />
      <br />
      In addition to a variety of languages, TileDB Embedded integrates with Apache Arrow, which further allows efficient interoperability
      with a wide set of SQL engines (MariaDB, Presto, Trino), computational frameworks (Spark, Dask), data science tools (pandas, Vaex,
      numpy) and machine learning tools (TensorFlow, PyTorch, scikit-learn), all using zero-copy techniques for maximizing performance.
    </>
  ),
};

const tabKeys = Object.keys(texts);

const Capabilities: React.FC = () => {
  const [tab, setTab] = useState('universal');
  const [hoveredTab, setHoveredTab] = useState<string | undefined>();

  const clearHoveredTab = () => {
    setHoveredTab(undefined);
  };

  const changeTab = useCallback((newTab: string) => {
    clearHoveredTab();
    setTab(newTab);
  }, []);

  const changeHoveredTab = useCallback(
    (newTab: string) => {
      if (tab === newTab) {
        return;
      }
      setHoveredTab(newTab);
    },
    [tab]
  );

  const currentTabIndex = tabKeys.indexOf(tab);
  const next = () => {
    if (currentTabIndex === tabKeys.length - 1) {
      return;
    }

    setTab(tabKeys[currentTabIndex + 1]);
  };
  const prev = () => {
    if (currentTabIndex === 0) {
      return;
    }

    setTab(tabKeys[currentTabIndex - 1]);
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        next();
      }
      if (eventData.dir === 'Right') {
        prev();
      }
    },
  });

  return (
    <div className="TDB-EmbeddedPage__Capabilities">
      <Container>
        <div className="TDB-EmbeddedPage__Capabilities__Inner">
          <div className="TDB-EmbeddedPage__Capabilities__Header">
            <SectionIcon>
              <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.3895 24.9631C38.3895 26.2894 38.2002 27.5684 37.9157 28.7998L41.1842 30.6944C41.7525 28.8943 42.0367 26.9525 42.0367 24.9626C42.0367 15.5363 35.4525 7.57847 26.642 5.49426V9.23653C33.4158 11.2735 38.3893 17.526 38.3893 24.9628L38.3895 24.9631Z"
                  fill="#0077FF"
                />
                <path
                  d="M6.08432 28.7998C5.75258 27.6156 5.6105 26.2892 5.6105 25.0102C5.6105 17.6208 10.5843 11.3208 17.3578 9.28392V5.54199C8.54733 7.579 1.96313 15.5367 1.96313 24.963C1.96313 26.9525 2.24729 28.8946 2.8156 30.6947L6.08432 28.7998Z"
                  fill="#0077FF"
                />
                <path
                  d="M22.0001 41.3526C17.595 41.3526 13.6633 39.6474 10.7264 36.8527L7.45789 38.7473C11.1053 42.5844 16.2684 45.0001 22 45.0001C27.7315 45.0001 32.8947 42.5844 36.4947 38.7001L33.2738 36.8055C30.3367 39.6475 26.3579 41.3527 22.0001 41.3527L22.0001 41.3526Z"
                  fill="#0077FF"
                />
                <path
                  d="M15.1317 13.6421C14.4213 14.3525 14.4213 15.5366 15.1317 16.2474L20.721 21.8367C21.4314 22.5471 22.6155 22.5471 23.3263 21.8367L28.9156 16.2474C29.2946 15.8684 29.4367 15.395 29.4367 14.9684C29.4367 14.4946 29.2473 14.0211 28.9156 13.6894C28.2052 12.979 27.021 12.979 26.3103 13.6894L23.847 16.1526L23.8473 1.84736C23.8473 0.852461 23.0421 0 22 0C21.0051 0 20.1526 0.805219 20.1526 1.84736V16.1052L17.6894 13.642C16.979 12.9316 15.842 12.9316 15.1317 13.6423L15.1317 13.6421Z"
                  fill="#0077FF"
                />
                <path
                  d="M14.3264 34.342C14.0422 35.3369 14.6581 36.3315 15.6054 36.6156C16.6003 36.8997 17.5949 36.2838 17.879 35.3366L19.9157 27.7103C20.1998 26.7153 19.5839 25.7208 18.6367 25.4367L11.0104 23.4C10.5365 23.2579 10.0154 23.3527 9.63684 23.5893C9.2106 23.8262 8.92645 24.2052 8.78438 24.7262C8.50022 25.7211 9.11612 26.7157 10.0634 26.9998L13.4267 27.8999L1.06359 34.9103C0.163512 35.4314 -0.120614 36.5208 0.400455 37.4209C0.873944 38.321 2.01089 38.6051 2.91098 38.1316L15.2741 30.9791L14.3264 34.342Z"
                  fill="#0077FF"
                />
                <path
                  d="M42.9369 34.9106L30.6211 27.8054L33.9844 26.9053C34.9794 26.6211 35.5476 25.6263 35.2635 24.6317C34.9793 23.6368 33.9844 23.0686 32.9899 23.3527L25.3636 25.3893C24.3687 25.6735 23.8004 26.6684 24.0846 27.6629L26.1209 35.2896C26.263 35.7634 26.5471 36.1897 26.9733 36.4265C27.3996 36.6633 27.8734 36.7582 28.3469 36.6158C29.3418 36.3317 29.91 35.3368 29.6259 34.3422L28.7258 30.9789L41.0889 38.1317C41.989 38.6528 43.0784 38.321 43.5995 37.4686C44.1205 36.5685 43.8364 35.4316 42.9363 34.9105L42.9369 34.9106Z"
                  fill="#0077FF"
                />
              </svg>
            </SectionIcon>
            <Typography as="h2" className="TDB-EmbeddedPage__Capabilities__Title" fontSize="overline" fontWeight="bold" color="brand-900">
              CAPABILITIES
            </Typography>
            <Typography
              as="h3"
              className="TDB-EmbeddedPage__Capabilities__Subtitle"
              color="neutral-800"
              fontSize="heading-1"
              fontWeight="bold"
              align="center"
            >
              Superior performance and versatility
            </Typography>
          </div>
        </div>
      </Container>

      <Container className="TDB-EmbeddedPage__Capabilities__ContentContainer">
        <div className="TDB-EmbeddedPage__Capabilities__Content">
          <Container className="TDB-EmbeddedPage__Capabilities__CubeContainer">
            <div className="TDB-EmbeddedPage__Capabilities__CubeWrapper">
              <Cube
                className={classNames('TDB-EmbeddedPage__Capabilities__Cube', `TDB-EmbeddedPage__Capabilities__Cube--clicked--${tab}`, {
                  [`TDB-EmbeddedPage__Capabilities__Cube--hovered--${hoveredTab}`]: hoveredTab,
                })}
              />
            </div>

            <div className="TDB-EmbeddedPage__Capabilities__Carousel" {...handlers}>
              <button
                disabled={currentTabIndex === 0}
                className={classNames('TDB-EmbeddedPage__Capabilities__Carousel__Control')}
                onClick={prev}
              >
                <img src={carretLeft} alt="arrow-left" />
              </button>
              <button
                disabled={currentTabIndex === tabKeys.length - 1}
                className={classNames('TDB-EmbeddedPage__Capabilities__Carousel__Control')}
                onClick={next}
              >
                <img src={carretRight} alt="arrow-right" />
              </button>
            </div>
          </Container>

          <div className="TDB-EmbeddedPage__Capabilities__TextAndTabs">
            <Container className="TDB-EmbeddedPage__Capabilities__TabsContainer">
              <div className="TDB-EmbeddedPage__Capabilities__Tabs">
                <Tab
                  active={tab === 'universal'}
                  onClick={() => changeTab('universal')}
                  onMouseOver={() => changeHoveredTab('universal')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Universal
                </Tab>
                <Tab
                  active={tab === 'performant'}
                  onClick={() => changeTab('performant')}
                  onMouseOver={() => changeHoveredTab('performant')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Performant
                </Tab>
                <Tab
                  active={tab === 'columnar'}
                  onClick={() => changeTab('columnar')}
                  onMouseOver={() => changeHoveredTab('columnar')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Columnar
                </Tab>
                <Tab
                  active={tab === 'cloud-native'}
                  onClick={() => changeTab('cloud-native')}
                  onMouseOver={() => changeHoveredTab('cloud-native')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Cloud-native
                </Tab>
                <Tab
                  active={tab === 'versioned'}
                  onClick={() => changeTab('versioned')}
                  onMouseOver={() => changeHoveredTab('versioned')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Versioned
                </Tab>
                <Tab
                  active={tab === 'interoperable'}
                  onClick={() => changeTab('interoperable')}
                  onMouseOver={() => changeHoveredTab('interoperable')}
                  onMouseOut={clearHoveredTab}
                  variant="large"
                >
                  Interoperable
                </Tab>
              </div>
            </Container>
            <Container className="TDB-EmbeddedPage__Capabilities__TextContainer">
              <div className="TDB-EmbeddedPage__Capabilities__Inner">
                <div className="TDB-EmbeddedPage__Capabilities__Text">
                  <Typography as="p" fontWeight="medium" color="neutral-600">
                    {texts[tab]}
                  </Typography>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Capabilities;
