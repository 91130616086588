import './Installation.scss';
import React, { useEffect, useState } from 'react';
import Typography from '@components/Typography';
import Tab from '@components/Tab';
import Container from '@components/Container';
import SectionIcon from '../components/SectionIcon';

const snippets: any = {
  python: `# Pip:
pip install tiledb

# Or Conda:
conda install -c conda-forge tiledb-py`,
  r: `# -- from CRAN
# install most recent release from CRAN
install.packages("tiledb")

# -- alternatively, install from source via GitHub
# install the 'remotes' package if needed
# install.packages("remotes")
install_github("TileDB-Inc/TileDB-R")

# -- verify install
tiledb::tiledb_version()
#> major minor patch 
#>     2     0     8`,
  go: `# Go Get
go get -v github.com/TileDB-Inc/TileDB-Go

# Go modules
go mod init github.com/<github_username>/repository_name`,
  c: `# Homebrew (macOS):
brew update
brew install tiledb-inc/stable/tiledb

# Or Conda (macOS, Linux, Windows):
conda install -c conda-forge tiledb

# Or download the pre-built release binaries from (Windows):
# https://github.com/TileDB-Inc/TileDB/releases`,
  docker: `docker pull tiledb/tiledb
docker run -it tiledb/tiledb`,
};

const languages: any = {
  python: 'bash',
  r: 'r',
  go: 'bash',
  c: 'bash',
  docker: 'bash',
};

const Installation: React.FC = () => {
  const [tab, setTab] = useState('python');

  useEffect(() => {
    const Prism = require('prismjs');
    // require('prismjs/plugins/line-numbers/prism-line-numbers.js');
    require('prismjs/components/prism-bash');
    require('prismjs/components/prism-python');
    require('prismjs/components/prism-r');

    Prism.highlightAll();
  }, [tab]);

  return (
    <div className="TDB-EmbeddedPage__Installation">
      <Container>
        <div className="TDB-EmbeddedPage__Installation__Inner">
          <SectionIcon>
            <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.53571 0.427734C4.0978 0.427734 0.5 4.02553 0.5 8.46345V30.9634C0.5 35.4014 4.0978 38.9992 8.53571 38.9992H37.4643C41.9022 38.9992 45.5 35.4014 45.5 30.9634V8.46345C45.5 4.02553 41.9022 0.427734 37.4643 0.427734H8.53571ZM10.5655 13.9342C9.93806 14.5616 9.93806 15.5793 10.5655 16.2071L15.6797 21.321L10.5655 26.4348C9.93806 27.0626 9.93806 28.0803 10.5655 28.7078C11.1933 29.3352 12.2107 29.3352 12.8384 28.7078L19.0888 22.4574C19.7162 21.8297 19.7162 20.8123 19.0888 20.1845L12.8384 13.9342C12.2107 13.3067 11.1933 13.3067 10.5655 13.9342ZM19.7859 27.7492C19.7859 26.8616 20.5055 26.142 21.393 26.142H31.0359C31.9234 26.142 32.643 26.8616 32.643 27.7492C32.643 28.6367 31.9234 29.3563 31.0359 29.3563H21.393C20.5055 29.3563 19.7859 28.6367 19.7859 27.7492Z"
                fill="#0077FF"
              />
            </svg>
          </SectionIcon>
          <Typography className="TDB-EmbeddedPage__Installation__Title" fontSize="heading-1" fontWeight="bold" color="neutral-800">
            Installation
          </Typography>
        </div>
      </Container>
      <div className="TDB-EmbeddedPage__Installation__Tabs">
        <Tab active={tab === 'python'} onClick={() => setTab('python')}>
          Python
        </Tab>
        <Tab active={tab === 'r'} onClick={() => setTab('r')}>
          R
        </Tab>
        <Tab active={tab === 'go'} onClick={() => setTab('go')}>
          Go
        </Tab>
        <Tab active={tab === 'c'} onClick={() => setTab('c')}>
          C / C++
        </Tab>
        <Tab active={tab === 'docker'} onClick={() => setTab('docker')}>
          Docker
        </Tab>
      </div>
      <Container>
        <div className="TDB-EmbeddedPage__Installation__Inner">
          <div className="TDB-EmbeddedPage__Installation__Code">
            <pre className={`code-box language-${languages[tab]}`}>
              <code className={`language-${languages[tab]}`}>{snippets[tab]}</code>
            </pre>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Installation;
