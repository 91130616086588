import './UsageCard.scss';
import React, { useEffect } from 'react';
import Typography from '@components/Typography';
import pythonIcon from './assets/python.svg';

interface UsageCardProps {
  title: string;
  codeSnippet: string;
  button?: {
    text: string;
    href: string;
    icon: string;
  };
}

const UsageCard: React.FC<UsageCardProps> = (props) => {
  const { title, codeSnippet, button } = props;

  useEffect(() => {
    const Prism = require('prismjs');
    // require('prismjs/plugins/line-numbers/prism-line-numbers.js');
    require('prismjs/components/prism-python');

    Prism.highlightAll();
  }, []);

  return (
    <div className="TDB-EmbeddedPage__Usage__UsageCard">
      <div className="TDB-EmbeddedPage__Usage__UsageCard__Head">
        <img src={pythonIcon} alt="python" />
        <Typography
          as="p"
          className="TDB-EmbeddedPage__Usage__UsageCard__Title"
          fontSize="heading-3"
          fontWeight="bold"
          color="neutral-main"
        >
          {title}
        </Typography>
      </div>

      <div className="TDB-EmbeddedPage__Usage__UsageCard__Content">
        <pre className="code-box language-python line-numbers">
          <code className="language-python">{codeSnippet}</code>
        </pre>
      </div>
      {button && (
        <a href={button?.href} className="TDB-EmbeddedPage__Usage__UsageCard__Action">
          <Typography color="neutral-main" fontWeight="semi-bold">
            {button?.text}
          </Typography>
          <img src={button?.icon} alt="" />
        </a>
      )}
    </div>
  );
};

export default UsageCard;
