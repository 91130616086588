import React, { useEffect, useState } from 'react';
import './ResourceCenter.scss';
import ClassName from '@utils/helpers/ClassName';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import JoinSlack from '@components/JoinSlack';
import fetchTileDBRepo from './fetchTileDBRepo';
import GithubLogo from './assets/github-logo.svg';
import Star from './assets/star.svg';
import Repo from './assets/repo.svg';
import ArrowRight from './assets/arrow-right.svg';
import Docs from './assets/documentation.svg';
import Forum from './assets/forum.svg';

interface Props {
  className?: string;
}

const ResourceCenter: React.FC<Props> = (props) => {
  const { className } = props;
  const [stars, setStars] = useState(1);

  useEffect(() => {
    fetchTileDBRepo().then((res: any) => {
      const count = res.stargazers_count;
      const countFormatted = (count / 1000).toFixed(1);
      setStars(countFormatted as any);
    });
  }, []);

  return (
    <div className={ClassName.join('TDB-ResourceCenter', className)}>
      <Typography className="TDB-ResourceCenter__Title" as="h2" fontSize="heading-1" fontWeight="bold" color="neutral-800" align="center">
        Resource center
      </Typography>
      <Container>
        <ResourceCenterCard className="TDB-ResourceCenter__Card--Github">
          <div className="github-text">
            <img className="github-logo" src={GithubLogo} alt="github-logo" />
            <Typography className="repo-description" fontSize="body" color="neutral-600">
              Join the growing TileDB Embedded open-source community on GitHub and shape the future of data management.
            </Typography>
          </div>
          <div className="github-widget">
            <Typography className="repo-name" fontSize="subheading-2" color="brand-800" fontWeight="semi-bold">
              <img className="repo-icon" src={Repo} alt="repository-name" />
              <p>
                <a className="repo-link" href={links.allRepos} target="_blank" rel="noreferrer noopener">
                  TileDB-Inc
                </a>{' '}
                /{' '}
                <a className="repo-link" href={links.core} target="_blank" rel="noreferrer noopener">
                  TileDB
                </a>
              </p>
            </Typography>
            <a className="github-stars" href={links.coreStarGazers} target="_blank" rel="noreferrer noopener">
              <div className="github-stars--left">
                <img className="star-icon" src={Star} alt="star" />
                <Typography className="star-text" fontWeight="semi-bold">
                  Star
                </Typography>
              </div>
              <div className="github-stars--right">
                <Typography className="star-text" fontWeight="semi-bold">
                  {stars}k
                </Typography>
              </div>
            </a>
            <Typography
              as="a"
              href={links.openSource.arrayStorage}
              rel="noreferrer noopener"
              className="all-repos"
              fontWeight="semi-bold"
              color="neutral-600"
            >
              See all repos
            </Typography>
          </div>
        </ResourceCenterCard>
        <div className="cards-group">
          <ResourceCenterCard>
            <img className="docs-img" src={Docs} alt="docs" />
            <div className="TDB-ResourceCenter__Card__Content">
              <div className="TDB-ResourceCenter__Card__Content">
                <div className="TDB-ResourceCenter__Card__Content__Text">
                  <Typography
                    as="h3"
                    className="TDB-ResourceCenter__Card__Title"
                    fontSize="subheading-1"
                    color="neutral-800"
                    fontWeight="semi-bold"
                  >
                    <img className="docs-img--mobile" src={Docs} alt="docs" />
                    Documentation
                  </Typography>
                  <Typography as="p" className="TDB-ResourceCenter__Card__Text" fontSize="body" color="neutral-600">
                    TileDB Embedded is a deep technology. There is a lot more to explore. Get started with our docs and check back for
                    updates.
                  </Typography>
                </div>
                <Typography
                  as="a"
                  href={links.docs}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="TDB-ResourceCenter__Card__Link"
                  fontSize="button-inline"
                  color="brand-800"
                  fontWeight="semi-bold"
                >
                  View docs
                  <img className="arrow-right" src={ArrowRight} alt="arrow-right" />
                </Typography>
              </div>
            </div>
          </ResourceCenterCard>
          <ResourceCenterCard>
            <img className="forum-img" src={Forum} alt="forum" />
            <div className="TDB-ResourceCenter__Card__Content">
              <div className="TDB-ResourceCenter__Card__Content">
                <div className="TDB-ResourceCenter__Card__Content__Text">
                  <Typography
                    as="h3"
                    className="TDB-ResourceCenter__Card__Title"
                    fontSize="subheading-1"
                    color="neutral-800"
                    fontWeight="semi-bold"
                  >
                    <img className="forum-img--mobile" src={Forum} alt="forum" />
                    Forum
                  </Typography>
                  <Typography as="p" className="TDB-ResourceCenter__Card__Text" fontSize="body" color="neutral-600">
                    Your use cases are shaping TileDB as the universal storage engine. Join the discussions on the TileDB forum.
                  </Typography>
                </div>
                <Typography
                  as="a"
                  href={links.forum}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="TDB-ResourceCenter__Card__Link"
                  fontSize="button-inline"
                  color="brand-800"
                  fontWeight="semi-bold"
                >
                  Visit forum
                  <img className="arrow-right" src={ArrowRight} alt="arrow-right" />
                </Typography>
              </div>
            </div>
          </ResourceCenterCard>
        </div>
        <JoinSlack />
      </Container>
    </div>
  );
};

interface ResourceCenterCardProps {
  className?: string;
}
const ResourceCenterCard: React.FC<ResourceCenterCardProps> = (props) => {
  const { children, className } = props;
  return <div className={ClassName.join('TDB-ResourceCenter__Card', className)}>{children}</div>;
};

export default ResourceCenter;
