import './Hero.scss';
import React from 'react';
import Button from '@components/Button';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import IconGithub from '@components/Icon/icons/IconGithub';

const Hero: React.FC = () => {
  return (
    <div className="TDB-EmbeddedPage__HeroWrapper">
      <div className="TDB-EmbeddedPage__HeroBackground" />
      <Container>
        <div className="TDB-EmbeddedPage__Hero">
          <Typography
            className="TDB-EmbeddedPage__Hero__Title"
            as="h1"
            fontSize="overline"
            fontWeight="bold"
            color="neutral-main"
            align="center"
          >
            TILEDB <span className="TDB-EmbeddedPage__Hero__Title__Emphasis">EMBEDDED</span>
          </Typography>
          <Typography
            className="TDB-EmbeddedPage__Hero__Subtitle"
            as="h2"
            fontSize="display-1"
            fontWeight="bold"
            color="neutral-main"
            align="center"
          >
            It starts with storage.
          </Typography>
          <Typography
            className="TDB-EmbeddedPage__Hero__Description"
            as="h3"
            fontSize="heading-5"
            fontWeight="medium"
            color="brand-100"
            align="center"
          >
            The most powerful storage engine: open-source, universal and blazingly fast.
          </Typography>
          <div className="TDB-EmbeddedPage__Hero__Buttons">
            <Button href={links.core} primary Icon={IconGithub}>
              GitHub
            </Button>
            <Button href={links.docs} outlined>
              Docs
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
