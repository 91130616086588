const fetchTileDBRepo = () =>
  typeof window !== 'undefined' &&
  window
    .fetch(`https://api.github.com/repos/TileDB-Inc/TileDB`)
    .then((response: any) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response: any) => response.json())
    .then((repo: any) => Promise.resolve(repo));

export default fetchTileDBRepo;
