import './StorageBackends.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import awsIcon from './assets/aws.svg';
import ramIcon from './assets/ram.png';
import lustreIcon from './assets/lustre.png';
import azureIcon from './assets/azure.svg';
import gcloudIcon from './assets/gcloud.svg';
import hadoopIcon from './assets/hadoop.svg';
import minioIcon from './assets/minio.svg';

const StorageBackends: React.FC = () => {
  return (
    <Container>
      <div className="TDB-EmbeddedPage__StorageBackends">
        <Typography
          fontSize="heading-1"
          fontWeight="bold"
          color="neutral-800"
          className="TDB-EmbeddedPage__StorageBackends__Title"
          align="center"
        >
          Diverse storage backends
        </Typography>
        <Typography
          fontSize="body-lg"
          fontWeight="medium"
          color="neutral-600"
          className="TDB-EmbeddedPage__StorageBackends__Description"
          align="center"
        >
          Utilize various storage backends, from your laptop to a distributed filesystem to a cloud object store, all without any code
          changes.
        </Typography>
        <div className="TDB-EmbeddedPage__StorageBackends__Icons">
          <div>
            <img src={awsIcon} alt="aws" />
            <img src={azureIcon} alt="azure" />
            <img src={gcloudIcon} alt="gcloud" />
          </div>
          <div>
            <img src={minioIcon} alt="minio" />
            <img src={hadoopIcon} alt="hadoop" />
            <img src={lustreIcon} alt="lustre" />
            <img src={ramIcon} alt="ram" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StorageBackends;
