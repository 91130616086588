import './ExtensibleFormat.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Tab from '@components/Tab';
import SectionIcon from '../components/SectionIcon';
import carretLeft from './assets/carret-left.svg';
import carretRight from './assets/carret-right.svg';
import tablesDenseArray from './assets/data-mapping/desktop/tables-dense_array.png';
import tableDenseVector from './assets/data-mapping/desktop/tables-dense_vector.png';
import tablesSparseArray from './assets/data-mapping/desktop/tables-sparse_array.png';
import imaging from './assets/data-mapping/desktop/imaging.png';
import pointCloud from './assets/data-mapping/desktop/point-cloud.png';
import genomicVariants from './assets/data-mapping/desktop/genomic-variants.png';
import financialTimeSeriesDense from './assets/data-mapping/desktop/financial_time-series_dense.png';
import financialTimeSeriesSparse from './assets/data-mapping/desktop/financial_time-series_sparse.png';
import graphs from './assets/data-mapping/desktop/graphs.png';
import tablesDenseArrayMobile from './assets/data-mapping/mobile/tables-dense_array_m.png';
import tableDenseVectorMobile from './assets/data-mapping/mobile/tables-dense_vector_m.png';
import tablesSparseArrayMobile from './assets/data-mapping/mobile/tables-sparse_array_m.png';
import imagingMobile from './assets/data-mapping/mobile/imaging_m.png';
import pointCloudMobile from './assets/data-mapping/mobile/point-cloud_m.png';
import genomicVariantsMobile from './assets/data-mapping/mobile/genomic-variants_m.png';
import financialTimeSeriesDenseMobile from './assets/data-mapping/mobile/financial_time-series_dense_m.png';
import financialTimeSeriesSparseMobile from './assets/data-mapping/mobile/financial_time-series_sparse_m.png';
import graphsMobile from './assets/data-mapping/mobile/graphs_m.png';

interface Item {
  text: string;
  image: string;
  mobileImage: string;
}

const data: any = {
  tables: [
    {
      text: 'Arrays subsume tables, with multiple data modelings that can be tailored to your application for maximizing performance. Dense vector: Slice on row ID as non-materialized integer dimension.',
      image: tableDenseVector,
      mobileImage: tableDenseVectorMobile,
    },
    {
      text: 'Arrays subsume tables, with multiple data modelings that can be tailored to your application for maximizing performance. Sparse array: 2D slicing on diverse data types (dates, strings, floats).',
      image: tablesSparseArray,
      mobileImage: tablesSparseArrayMobile,
    },
    {
      text: 'Arrays subsume tables, with multiple data modelings that can be tailored to your application for maximizing performance. Dense array with labeled dimensions: Map arbitrary types to 2D integers.',

      image: tablesDenseArray,
      mobileImage: tablesDenseArrayMobile,
    },
  ],
  imaging: [
    {
      text: 'An image is a 2D dense array, where each cell is a pixel that can store RGBA and other various values.',
      image: imaging,
      mobileImage: imagingMobile,
    },
  ],
  'point-cloud': [
    {
      text: 'Point cloud data is a 3D sparse array with float coordinates as dimensions.',
      image: pointCloud,
      mobileImage: pointCloudMobile,
    },
  ],
  'genomic-variants': [
    {
      text: 'Genomic variants can be modeled by a 3D sparse array defined by contig (string), position (integer) and sample id (string) dimensions.',
      image: genomicVariants,
      mobileImage: genomicVariantsMobile,
    },
  ],
  'financial-time-series': [
    {
      text: 'Time-series data can be modeled by a 2D array, either dense with labeled dimensions or sparse with datetime and string dimensions.',
      image: financialTimeSeriesDense,
      mobileImage: financialTimeSeriesDenseMobile,
    },
    {
      text: 'Time-series data can be modeled by a 2D array, either dense with labeled dimensions or sparse with datetime and string dimensions.',
      image: financialTimeSeriesSparse,
      mobileImage: financialTimeSeriesSparseMobile,
    },
  ],
  graphs: [
    {
      text: 'Graphs can be modeled as adjacency matrices, which are 2D sparse arrays.',
      image: graphs,
      mobileImage: graphsMobile,
    },
  ],
};

interface CarouselProps {
  items: Item[];
  onChange: (index: number) => void;
  index: number;
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const { items = [], index, onChange } = props;

  const prev = () => {
    if (index === 0) {
      return;
    }
    onChange(index - 1);
  };

  const next = () => {
    if (index === items.length - 1) {
      return;
    }
    onChange(index + 1);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        next();
      }
      if (eventData.dir === 'Right') {
        prev();
      }
    },
  });

  return (
    <div
      className={classNames('TDB-EmbeddedPage__ExtensibleFormat__Carousel', {
        'TDB-EmbeddedPage__ExtensibleFormat__Carousel--no-controls': items.length < 2,
      })}
      {...handlers}
    >
      {/**
       * Preload all photos for a smooth result on carousel changes
       */}
      <div className="TDB-EmbeddedPage__Prerender__financial_time-series_dense" />
      <div className="TDB-EmbeddedPage__Prerender__financial_time-series_dense_m" />
      <div className="TDB-EmbeddedPage__Prerender__financial_time-series_sparse" />
      <div className="TDB-EmbeddedPage__Prerender__financial_time-series_sparse_m" />
      <div className="TDB-EmbeddedPage__Prerender__genomic-variants" />
      <div className="TDB-EmbeddedPage__Prerender__genomic-variants_m" />
      <div className="TDB-EmbeddedPage__Prerender__graphs" />
      <div className="TDB-EmbeddedPage__Prerender__graphs_m" />
      <div className="TDB-EmbeddedPage__Prerender__imaging" />
      <div className="TDB-EmbeddedPage__Prerender__imaging_m" />
      <div className="TDB-EmbeddedPage__Prerender__point-cloud" />
      <div className="TDB-EmbeddedPage__Prerender__point-cloud_m" />
      <div className="TDB-EmbeddedPage__Prerender__tables-dense_array" />
      <div className="TDB-EmbeddedPage__Prerender__tables-dense_array_m" />
      <div className="TDB-EmbeddedPage__Prerender__tables-dense_vector" />
      <div className="TDB-EmbeddedPage__Prerender__tables-dense_vector_m" />
      <div className="TDB-EmbeddedPage__Prerender__tables-sparse_array" />
      <div className="TDB-EmbeddedPage__Prerender__tables-sparse_array_m" />
      <div className="TDB-EmbeddedPage__ExtensibleFormat__Carousel">
        <div className="TDB-EmbeddedPage__ExtensibleFormat__Carousel__Top">
          <button disabled={index === 0} className={classNames('TDB-EmbeddedPage__ExtensibleFormat__Carousel__Control')} onClick={prev}>
            <img src={carretLeft} alt="arrow-left" />
          </button>
          <div className="TDB-EmbeddedPage__ExtensibleFormat__Carousel__Content">
            <img
              className="TDB-EmbeddedPage__ExtensibleFormat__Carousel__Image TDB-EmbeddedPage__ExtensibleFormat__Carousel__Image--desktop"
              src={items[index].image}
              alt=""
            />
            <img
              className="TDB-EmbeddedPage__ExtensibleFormat__Carousel__Image TDB-EmbeddedPage__ExtensibleFormat__Carousel__Image--mobile"
              src={items[index].mobileImage}
              alt=""
            />
          </div>
          <button
            disabled={index === items.length - 1}
            className={classNames('TDB-EmbeddedPage__ExtensibleFormat__Carousel__Control')}
            onClick={next}
          >
            <img src={carretRight} alt="arrow-right" />
          </button>
        </div>
      </div>
      <div className="TDB-EmbeddedPage__ExtensibleFormat__Carousel__BulletsWrapper">
        {items.length > 1 && (
          <>
            {items.map((item, i) => (
              <button
                aria-label="image-nav"
                onClick={() => onChange(i)}
                key={item.image}
                className={classNames('TDB-EmbeddedPage__ExtensibleFormat__Carousel__Bullet', {
                  'TDB-EmbeddedPage__ExtensibleFormat__Carousel__Bullet--active': index === i,
                })}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const ExtensibleFormat: React.FC = () => {
  const [tab, setTab] = useState('tables');
  const [itemIndex, setItemIndex] = useState(0);

  const changeTab = (t: string) => {
    // reset carousel on tab change
    setItemIndex(0);
    setTab(t);
  };

  return (
    <div className="TDB-EmbeddedPage__ExtensibleFormat">
      <link rel="preload" href={tableDenseVector} as="image" />
      <Container>
        <div className="TDB-EmbeddedPage__ExtensibleFormat__Inner">
          <SectionIcon>
            <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25006 3.75V20.625H21.1251V3.75H4.25006ZM4.25006 24.375V41.25H21.1251V24.375H4.25006ZM24.875 41.25V24.375H41.75V41.25H24.875ZM24.875 5.625V20.625H39.875V5.625H24.875Z"
                fill="#0070F0"
              />
            </svg>
          </SectionIcon>
          <Typography className="TDB-EmbeddedPage__ExtensibleFormat__Title" fontSize="overline" fontWeight="bold" color="brand-900">
            ARRAYS
          </Typography>
          <Typography
            className="TDB-EmbeddedPage__ExtensibleFormat__Subtitle"
            fontSize="heading-1"
            color="neutral-800"
            fontWeight="bold"
            align="center"
          >
            A universal data format
          </Typography>
          <Typography
            className="TDB-EmbeddedPage__ExtensibleFormat__Description"
            color="neutral-600"
            fontWeight="medium"
            fontSize="heading-5"
            align="center"
          >
            Use arrays to capture the natural structure of your data
          </Typography>
        </div>
      </Container>

      <div className="TDB-EmbeddedPage__ExtensibleFormat__Tabs">
        <Tab active={tab === 'tables'} onClick={() => changeTab('tables')}>
          Tables
        </Tab>
        <Tab active={tab === 'imaging'} onClick={() => changeTab('imaging')}>
          Imaging
        </Tab>
        <Tab active={tab === 'point-cloud'} onClick={() => changeTab('point-cloud')}>
          Point-cloud
        </Tab>
        <Tab active={tab === 'genomic-variants'} onClick={() => changeTab('genomic-variants')}>
          Genomic variants
        </Tab>
        <Tab active={tab === 'financial-time-series'} onClick={() => changeTab('financial-time-series')}>
          Time-series
        </Tab>
        <Tab active={tab === 'graphs'} onClick={() => changeTab('graphs')}>
          Graphs
        </Tab>
      </div>
      <Container>
        <div className="TDB-EmbeddedPage__ExtensibleFormat__Inner">
          <div className="TDB-EmbeddedPage__ExtensibleFormat__ImageWrapper">
            <Carousel items={data[tab]} index={itemIndex} onChange={setItemIndex} />
          </div>
          <div className="TDB-EmbeddedPage__ExtensibleFormat__Text">
            <Typography as="p" fontWeight="medium" color="brand-800" align="center">
              {data[tab][itemIndex].text}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ExtensibleFormat;
