import './Usage.scss';
import React, { useState } from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import UsageCard from './UsageCard';
import iconNewTab from './assets/new-tab.svg';
import iconDownload from './assets/download.svg';
import UsageTab from './UsageTab';

const dataframeCodeSnippet = `import tiledb, tiledb.sql
import numpy as np
import pandas as pd

# Assumes we ingested the NYC taxi dataset as a 1D sparse array indexed on
# tpep_pickup_datetime, with the other 17 fields as attributes
A = tiledb.open("nyc_taxi_2019-12")

# Get the fare amount and trip distance for all rides in the 
# first two hours of December 1, 2019 in a pandas dataframe
start = np.datetime64('2019-12-01T00:00:00')
end = np.datetime64('2019-12-01T02:00:00')
df = A.query(attrs=['trip_distance', 'fare_amount']).df[start:end]

# Run any SQL query, retrieving the results in pandas.
db = tiledb.sql.connect()
pd.read_sql(sql=
"""
select trip_distance from \`nyc_taxi_2019-12\` 
where fare_amount > 3.0
""", 
con=db)
`;

const imageCodeSnippet = `import tiledb
from PIL import Image # for image rendering

# Assumes we ingested a SAR image of the Autzen Stadium, 
# using GDAL converting a GeoTIFF into a dense TileDB array.
# Y domain=(0, 2047), X domain=(0, 1023)
# TDB_VALUES_1 attribute stores grayscale intensity for each pixel, 16-bit int
A = tiledb.open("stadium_sar_data")

# Slice and plot a portion of the image
image_data = A[400:1500, 0:925]
img = Image.fromarray(image_data["TDB_VALUES_1"], 'I;16')`;

const Usage: React.FC = () => {
  const [tab, setTab] = useState('dataframe');

  return (
    <div className="TDB-EmbeddedPage__UsageWrapper">
      <div className="TDB-EmbeddedPage__UsageWrapper__Background" />
      <div className="TDB-EmbeddedPage__UsageWrapper__RadialGradient" />
      <Container>
        <div className="TDB-EmbeddedPage__Usage">
          <Typography
            as="p"
            className="TDB-EmbeddedPage__Usage__Title"
            color="neutral-main"
            fontSize="overline"
            fontWeight="bold"
            align="center"
          >
            USAGE
          </Typography>
          <Typography
            as="p"
            className="TDB-EmbeddedPage__Usage__Subtitle"
            color="neutral-main"
            fontSize="heading-1"
            fontWeight="bold"
            align="center"
          >
            One API, N Possibilities
          </Typography>
          <Typography
            as="p"
            className="TDB-EmbeddedPage__Usage__Description"
            color="neutral-100"
            fontSize="heading-5"
            fontWeight="medium"
            align="center"
          >
            Slicing is the most common operation in TileDB Embedded. Here are some simple examples, using sparse and dense TileDB arrays.
          </Typography>
          <div className="TDB-EmbeddedPage__Usage__Tabs">
            <UsageTab active={tab === 'dataframe'} onClick={() => setTab('dataframe')}>
              Dataframe
            </UsageTab>
            <UsageTab active={tab === 'image'} onClick={() => setTab('image')}>
              Image
            </UsageTab>
          </div>
          <div className="TDB-EmbeddedPage__Usage__Cards">
            {tab === 'dataframe' && (
              <UsageCard
                title="Dataframe, 1D Sparse Array"
                // button={{ text: 'See the array on TileDB Cloud', href: '', icon: iconNewTab }}
                codeSnippet={dataframeCodeSnippet}
              />
            )}
            {tab === 'image' && (
              <UsageCard
                title="Image, 1D Dense Array"
                // button={{ text: 'Download the complete notebook', href: '', icon: iconDownload }}
                codeSnippet={imageCodeSnippet}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Usage;
