import './SectionIcon.scss';
import React from 'react';

const SectionIcon: React.FC = (props) => {
  const { children } = props;

  return (
    <div className="TDB-EmbeddedPage__SectionIcon">
      <div className="TDB-EmbeddedPage__SectionIcon__Inner">
        <div className="TDB-EmbeddedPage__SectionIcon__Line TDB-EmbeddedPage__SectionIcon__Line--left" />
        <div className="TDB-EmbeddedPage__SectionIcon__IconWrapper">{children}</div>
        <div className="TDB-EmbeddedPage__SectionIcon__Line TDB-EmbeddedPage__SectionIcon__Line--right" />
      </div>
    </div>
  );
};

export default SectionIcon;
