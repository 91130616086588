import React from 'react';
import { createSVGIcon } from '../Icon';

export default createSVGIcon(
  <>
    <path fill="none" d="M0 0H48V48H0z" />
    <path
      fill="#fff"
      d="M118.467 102.861a18.457 18.457 0 0 0-5.835 35.966c.923.171 1.261-.4 1.261-.888 0-.44-.017-1.894-.025-3.436-5.135 1.117-6.22-2.176-6.22-2.176a4.885 4.885 0 0 0-2.05-2.7c-1.675-1.145.123-1.122.123-1.122a3.906 3.906 0 0 1 2.833 1.9 3.929 3.929 0 0 0 5.37 1.533 3.908 3.908 0 0 1 1.171-2.463c-4.1-.467-8.409-2.05-8.409-9.115a7.144 7.144 0 0 1 1.9-4.954 6.63 6.63 0 0 1 .179-4.884s1.549-.493 5.076 1.892a17.5 17.5 0 0 1 9.238 0c3.535-2.394 5.078-1.9 5.078-1.9a6.636 6.636 0 0 1 .181 4.884 7.128 7.128 0 0 1 1.9 4.954c0 7.088-4.317 8.649-8.427 9.106a4.414 4.414 0 0 1 1.251 3.418c0 2.463-.021 4.456-.021 5.065 0 .493.333 1.067 1.267.886a18.457 18.457 0 0 0-5.844-35.966z"
      transform="translate(-94.514 -96.86)"
    />
  </>,
  'github'
);
