import './Tab.scss';
import classNames from 'classnames';
import React from 'react';
import Typography from '../Typography';

interface TabProps {
  className?: string;
  onClick: () => void;
  active: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  variant?: 'medium' | 'large';
  id?: string;
}

const Tab: React.FC<TabProps> = (props) => {
  const { className, children, onClick, active, onMouseOver, onMouseOut, variant = 'medium', id } = props;

  return (
    <button
      onClick={onClick}
      className={classNames('TDB-Tab', className, `TDB-Tab--${variant}`, { 'TDB-Tab--active': active })}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseOut={onMouseOut}
      onBlur={onMouseOut}
      id={id}
    >
      <Typography fontSize="subheading-3" fontWeight="medium" color="neutral-400">
        {children}
      </Typography>
    </button>
  );
};

export default Tab;
