import './EmbeddedInteroperability.scss';
import React from 'react';
import Interoperability from '../../common/Interoperability';
import SectionIcon from '../components/SectionIcon';
import StorageBackends from './StorageBackends';

const EmbeddedInteroperability: React.FC = () => {
  return (
    <div className="TDB-EmbeddedPage__Interoperability">
      <SectionIcon>
        <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M43.2555 33.9205L45.3901 28.7445C45.6747 28.0797 45.3901 27.2725 44.726 26.9875L39.935 24.6132C40.1246 23.1885 40.1246 21.8115 39.935 20.4342L44.726 18.0125C45.3901 17.6799 45.6747 16.9203 45.3901 16.2555L43.2555 11.0795C42.971 10.4147 42.2119 10.0348 41.5478 10.2723L36.4719 11.9818C35.6182 10.8897 34.6218 9.89227 33.4833 9.03767L35.191 3.95637C35.4283 3.2442 35.096 2.53167 34.3846 2.24683L29.2141 0.109995C28.55 -0.174877 27.7436 0.109995 27.459 0.774799L25.0871 5.57092C23.6639 5.38112 22.2884 5.38112 20.9126 5.57092L18.4934 0.774799C18.1612 0.109995 17.4024 -0.17486 16.7383 0.109995L11.5678 2.24683C10.9037 2.53171 10.5714 3.29155 10.7614 3.95637L12.4691 9.03767C11.3782 9.89227 10.3818 10.8897 9.52811 11.9818L4.45219 10.2723C3.74077 10.0348 3.029 10.3673 2.74446 11.0795L0.609879 16.2555C0.325308 16.9203 0.609879 17.7275 1.27398 18.0125L6.06503 20.3868C5.87542 21.7638 5.87542 23.1885 6.06503 24.5658L1.27398 26.9875C0.609879 27.3201 0.325325 28.0797 0.609879 28.7445L2.74446 33.9205C3.02903 34.5853 3.78807 34.9179 4.45219 34.7277L9.52811 33.0182C10.3818 34.1103 11.3782 35.1077 12.5167 35.9623L10.809 41.0436C10.5717 41.7558 10.904 42.5156 11.6154 42.7532L16.7859 44.89C17.45 45.1749 18.2564 44.89 18.541 44.2252L20.9129 39.4291C22.3361 39.6189 23.7116 39.6189 25.0874 39.4291L27.5066 44.2252C27.8388 44.89 28.5976 45.1749 29.2617 44.89L34.4322 42.7532C35.0963 42.4683 35.4286 41.7084 35.2386 41.0436L33.5309 35.9623C34.6218 35.1077 35.6182 34.1103 36.4719 33.0182L41.5478 34.7277C42.2119 34.9175 42.971 34.5853 43.2555 33.9205ZM19.1576 31.6409C14.1293 29.5513 11.7098 23.7579 13.7971 18.6769C15.8844 13.5956 21.6717 11.2212 26.7474 13.3107C31.8233 15.4002 34.2424 21.1937 32.1079 26.2747C30.0209 31.356 24.2336 33.7304 19.1576 31.6409Z"
            fill="#0070F0"
          />
        </svg>
      </SectionIcon>
      <Interoperability
        title="APIs and integrations"
        description="Choose from a growing set of language APIs, popular data science tools and research workflows."
      />
      <StorageBackends />
    </div>
  );
};

export default EmbeddedInteroperability;
