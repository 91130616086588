import React from 'react';
import './JoinSlack.scss';
import ClassName from '@utils/helpers/ClassName';
import links from '@constants/links';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Slack from './assets/slack.svg';

interface Props {
  className?: string;
}

const JoinSlack: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={ClassName.join('TDB-JoinSlack', className)}>
      <div className="TDB-JoinSlack__Left">
        <img className="slack-logo" src={Slack} alt="slack" />
        <div className="TDB-JoinSlack__Text">
          <div className="TDB-JoinSlack__Title">
            <Typography as="h3" fontSize="subheading-1" color="neutral-main" fontWeight="semi-bold">
              Join the conversation
            </Typography>
            <div className="TDB-JoinSlack__Avatars">
              <div className="TDB-JoinSlack__Avatars__Item" />
              <div className="TDB-JoinSlack__Avatars__Item" />
              <div className="TDB-JoinSlack__Avatars__Item" />
              <div className="TDB-JoinSlack__Avatars__Item" />
            </div>
          </div>
          <Typography className="TDB-JoinSlack__body" as="p" fontSize="body" color="neutral-main">
            We invite you to participate in our Slack Channel. Come up with questions, get answers and become a part of the TileDB
            Community.
          </Typography>
        </div>
      </div>
      <div className="TDB-JoinSlack__ButtonWrapper">
        <Button primary className="btn--join-slack" href={links.joinSlack} rel="noreferrer noopener" target="_blank">
          Join Slack
        </Button>
      </div>
    </div>
  );
};

export default JoinSlack;
