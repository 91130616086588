import './EmbeddedPage.scss';
import React from 'react';
import CloudInAction from '@components/CloudInAction';
import Footer from '@components/Footer';
import Header from '@components/Header';
import links from '@constants/links';
import IndexLayout from '@layouts/index';
import ResourceCenter from './ResourceCenter';
import Hero from './Hero';
import Usage from './Usage';
import EmbeddedInteroperability from './EmbeddedInteroperability';
import Installation from './Installation';
import Capabilities from './Capabilities';
import ExtensibleFormat from './ExtensibleFormat';
import shareImage from './assets/TileDB_Embedded_thumb.png';

const EmbeddedPage: React.FC = () => {
  return (
    <IndexLayout
      pageName="embedded"
      helmet={{
        title: 'TileDB Embedded - The Universal Storage Engine',
        description:
          'TileDB Embedded is an open-source storage engine that structures any data as multi-dimensional arrays and is cloud-native for superior performance.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1313,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main">
        <Hero />
        <ExtensibleFormat />
        <Capabilities />
        <EmbeddedInteroperability />
        <Installation />
        <Usage />
        <ResourceCenter />
        <CloudInAction
          className="TDB-EmbeddedPage__CloudInAction"
          title="Scale queries and collaborate in TileDB Cloud"
          cta={{ text: 'Learn more', to: links.cloud }}
        />
      </main>
      <Footer />
    </IndexLayout>
  );
};

export default EmbeddedPage;
