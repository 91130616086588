import './UsageTab.scss';
import classNames from 'classnames';
import React from 'react';
import Typography from '@components/Typography';

interface UsageTabProps {
  className?: string;
  onClick: () => void;
  active: boolean;
}

const UsageTab: React.FC<UsageTabProps> = (props) => {
  const { className, children, onClick, active } = props;

  return (
    <button
      onClick={onClick}
      className={classNames('TDB-EmbeddedPage__UsageTab', className, { 'TDB-EmbeddedPage__UsageTab--active': active })}
    >
      <Typography fontSize="subheading-3" fontWeight="medium" color="brand-washed">
        {children}
      </Typography>
    </button>
  );
};

export default UsageTab;
